<template>
  <NotPermission v-if="!authenticated" />
  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'Etiquetas' }, { name: 'Listado' }]" />
    <v-card>
      <v-toolbar color="grey darken-1" dark card>
        <v-toolbar-title>Etiquetas</v-toolbar-title>
        <v-spacer />
        <v-btn :to="{ name: 'CrearEtiqueta' }" color="primary">
         <svg style="width:24px;height:24px; margin-right:10px;" viewBox="0 0 24 24">
              <path fill="currentColor" d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z" />
          </svg>
          Agregar Etiqueta
        </v-btn>
      </v-toolbar>
      <v-container fluid grid-list-lg>
        <v-layout row wrap>
          <v-flex xs12>
            <v-data-table
              :headers="[
                { text: 'Nombre', value: 'nombre' },
                { text: 'Categoría', value: 'titulo' },
                { text: 'Tipo', value: 'tipo' }
              ]"
              :items="etiquetas"
              :loading="loadingEtiquetas"
              :rows-per-page-items="[25, 35, 50]"
              class="elevation-1"
            >
              <tr slot="items" slot-scope="props">
                <td class="px-3">
                  {{ props.item.nombre }}
                </td>
                <td class="px-3">
                  {{ props.item.titulo }}
                </td>
                <td class="px-3">
                  {{ props.item.tipo }}
                </td>
                <td class="text-xs-center px-3">
                  <v-btn
                    class="ma-0"
                    :to="{ name: 'EditEtiqueta', params: { id: props.item.id } }"
                    small
                    icon
                    flat
                    color="info"
                  >
                    <v-icon small>
                      edit
                    </v-icon>
                  </v-btn>
                  <v-btn
                    class="ma-0"
                    small
                    @click="openModalDeleteEtiqueta(props.item)"
                    icon
                    flat
                    color="error"
                  >
                    <v-icon small>
                      delete
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
    <ModalDelete />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return { title: "Listado de Etiquetas" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    ModalDelete: () => import("@/views/etiquetas/ModalDelete")
  },

  data() {
    return {};
  },

  computed: {
    ...mapState({
      etiquetas: state => state.etiquetas.etiquetas,
      loadingEtiquetas: state => state.etiquetas.loadingEtiquetas
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user"
    })
  },
  created() {
    this.getEtiquetas();
  },
  methods: {
    ...mapActions({
      getEtiquetas: "etiquetas/getEtiquetas",
      replaceCurrentEtiqueta: "etiquetas/replaceCurrentEtiqueta",
      replaceShowModalDeleteEtiqueta: "etiquetas/replaceShowModalDeleteEtiqueta"
    }),
    openModalDeleteEtiqueta(etiqueta) {
      this.replaceCurrentEtiqueta({ etiqueta });
      this.replaceShowModalDeleteEtiqueta({ status: true });
    }
  }
};
</script>
